import React from "react"
import styles from "./header-webinar-banner.module.scss"
import { Link } from "gatsby"
import { useState } from "react";

const HeaderWebinarBanner = ({ webinarURL }) => {

  const [show, setShow] = useState(true);

  const toggleShow = () => {
    setShow(current => !current);
  };

  return (
    show ?
      <>
        <div className={styles.banner_wrapper}>
          <div className={styles.white_wrapper}>
            <div className={styles.webinar_wrapper}>
              <img
                alt='icone'
                src={require("../../../../images/webinar-banner/webinar-icon.svg")}
              />
              Вебинар
            </div>
            <div className={styles.text_wrapper}>Сотрудники VS самозанятые: инструкция по применению</div>
          </div>
          <img
            alt='arrow'
            className={styles.arrow}
            src={require("../../../../images/webinar-banner/right-arrow.svg")}
          />
          <Link to={webinarURL} target="_blank" className={styles.cta}>
            Участвовать
          </Link>
          <a className={styles.close} onClick={toggleShow}>
            <img alt='close' src={require("../../../../images/webinar-banner/close.svg")} />
          </a>
        </div>
      </> : null
  )
}

export default HeaderWebinarBanner
